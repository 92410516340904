import React from 'react'
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl'

import {changePage, selectPage, openMenu, closeMenu} from '../actions/index.js'


const mapStateToProps = (state) => {
	return {
		menu: state.page.menu,
		currentPage: state.page.currentPage
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		selectPage: page => dispatch(selectPage(page)),
		changePage: page => dispatch(changePage(page)),
		closeMenu: page => dispatch(closeMenu()),
		openMenu: page => dispatch(openMenu())
	};
};

class Menu extends React.Component {
	toggleMenu = () => {
		if(this.props.menu){
			this.props.closeMenu();
		} else {
			this.props.openMenu();
		}
	};
	
	selectPage = (number) => {
		this.props.selectPage(number);
		this.props.scrollToSlide(number);
	};
	
	render() {
		let menuClass = 'menu';
		if(this.props.menu){
			menuClass+= ' active';
		}
		if(this.props.currentPage === 0){
			menuClass+= ' hidden';
		}
		return (
			<div className={menuClass}>
				<div className="top-bar">
					<img src="/images/logo_white.png" alt="Cellyons' logo" className="cellyon-logo"/>
					<div className="menu-button" onClick={this.toggleMenu}>
						<img src="/images/menubar.png" alt="menubar top" className="top"/>
						<img src="/images/menubar.png" alt="menubar bottom" className="bottom"/>
					</div>
				</div>
				<div className="menu-content">
					<ul>
						<FormattedMessage id="menu.cover">{txt => <li className="indent1" onClick={() => this.selectPage(0)}>{txt}</li>}</FormattedMessage>
						<FormattedMessage id="menu.about">{txt => <li className="indent2" onClick={() => this.selectPage(1)}>{txt}</li>}</FormattedMessage>
						<FormattedMessage id="menu.gameplay">{txt => <li className="indent3" onClick={() => this.selectPage(2)}>{txt}</li>}</FormattedMessage>
						<FormattedMessage id="menu.hunt">{txt => <li className="indent4" onClick={() => this.selectPage(4)}>{txt}</li>}</FormattedMessage>
						<FormattedMessage id="menu.bossmaker">{txt => <li className="indent3" onClick={() => this.selectPage(5)}>{txt}</li>}</FormattedMessage>
						<FormattedMessage id="menu.comingsoon">{txt => <li className="indent2" onClick={() => this.selectPage(6)}>{txt}</li>}</FormattedMessage>
						<FormattedMessage id="menu.joinus">{txt => <li className="indent1" onClick={() => this.selectPage(7)}>{txt}</li>}</FormattedMessage>
					</ul>
				</div>
				<div className="social-media">
					<a href="https://discord.gg/ErnVfzM" className="discord">
						<FormattedMessage id="joindiscord">{txt => <p>{txt}</p>}</FormattedMessage>
						<div className="link-image"></div>
					</a>
					<a href="https://store.steampowered.com/app/802120/Cellyon_Boss_Confrontation" className="steam">
						<FormattedMessage id="joinsteam">{txt => <p>{txt}</p>}</FormattedMessage>
						<div className="link-image"></div>
					</a>
				</div>
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);