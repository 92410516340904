import React from 'react'
import Slider from 'react-slick';
import {FormattedMessage} from 'react-intl'

class BossMaker extends React.Component {
	render() {
		let carouselSettings = {
				dots: true,
				dotsClass : 'carousel-dots'
		};
		return (
			<div className="page bossmaker">
				<Slider {...carouselSettings}>
					<div className="carousel-slide">
						<div>
							<FormattedMessage id="bossmaker0.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
							<FormattedMessage id="bossmaker0.body">{txt => <p>{txt}</p>}</FormattedMessage>
						</div>
					</div>
					<div className="carousel-slide spacer-left">
						<div className="fade-index">01</div>
						<div>
							<FormattedMessage id="bossmaker1.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
							<FormattedMessage id="bossmaker1.body">{txt => <p>{txt}</p>}</FormattedMessage>
						</div>
					</div>
					<div className="carousel-slide spacer-right">
						<div className="fade-index">02</div>
						<div>
							<FormattedMessage id="bossmaker2.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
							<FormattedMessage id="bossmaker2.body">{txt => <p>{txt}</p>}</FormattedMessage>
						</div>
					</div>
					<div className="carousel-slide spacer-left">
						<div className="fade-index">03</div>
						<div>
							<FormattedMessage id="bossmaker3.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
							<FormattedMessage id="bossmaker3.body">{txt => <p>{txt}</p>}</FormattedMessage>
						</div>
					</div>
				</Slider>
			</div>
		);
	}
}

export default BossMaker