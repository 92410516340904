import React from 'react'
import { connect } from 'react-redux';
import { FullPage, Slide } from 'react-full-page';

import {IntlProvider} from 'react-intl'
import messages_fr from '../lang/fr.json';
import messages_en from '../lang/en.json';

import {changePage, changeLocale} from '../actions/index.js'

import Menu from './Menu'
import Cover from './Cover'
import About from './About'
import Gameplay from './Gameplay'
import Classes from './Classes'
import Hunt from './Hunt'
import BossMaker from './BossMaker'
import ComingSoon from './ComingSoon'
import JoinUs from './JoinUs'

const mapStateToProps = (state) => {
	return {
		page: state.page,
		locale: state.locale
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changePage: page => dispatch(changePage(page)),
		changeLocale: locale => dispatch(changeLocale(locale))
	};
};

const messages = {
    'fr': messages_fr,
    'en': messages_en
};

class App extends React.Component {
	constructor(props) {
		super(props);

		let language = navigator.language.split(/[-_]/)[0];
		if(this.props.locale !== language){
			this.props.changeLocale(language);
		}
	}

	pageChanged = (pages) => {
		this.props.changePage(pages.to);
	}

	render() {
		console.log(this.props);
		return (
				<IntlProvider
					locale={this.props.locale}
					defaultLocale="en"
					messages={messages[this.props.locale]}
				>
					<FullPage controls={Menu} afterChange={this.pageChanged}>
						<Slide><Cover /></Slide>
						<Slide><About /></Slide>
						<Slide><Gameplay /></Slide>
						<Slide><Classes /></Slide>
						<Slide><Hunt /></Slide>
						<Slide><BossMaker /></Slide>
						<Slide><ComingSoon /></Slide>
						<Slide><JoinUs /></Slide>
					</FullPage>
				</IntlProvider>
		);
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);