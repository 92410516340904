import {CHANGE_PAGE, SELECT_PAGE, OPEN_MENU, CLOSE_MENU, OPEN_TRAILER, CLOSE_TRAILER, SELECT_BOSS} from '../constants/actionType.js'

const initialState = {
		currentPage: 0,
		menu: false,
		bossSelected: null,
		trailer: false
};

export default function page(state = initialState, action) {
	switch (action.type) {
		case CHANGE_PAGE:
			return {...state, currentPage: action.page};
		case SELECT_PAGE:
			return {...state, currentPage: action.page, menu: false};
		case OPEN_MENU:
			return {...state, menu: true};
		case CLOSE_MENU:
			return {...state, menu: false};
		case SELECT_BOSS:
			return {...state, bossSelected: action.boss};
		case OPEN_TRAILER:
			return {...state, trailer: true};
		case CLOSE_TRAILER:
			return {...state, trailer: false};
		default:
			return state;
	}
}