import React from 'react'
import { connect } from 'react-redux';

import {changeLocale} from '../actions/index'
import localeList from '../lang/localeList'

const mapStateToProps = (state) => {
	return {
		locale: state.locale
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		changeLocale: locale => dispatch(changeLocale(locale))
	};
};

class LanguageSwitcher extends React.Component {
	changeLocale(locale){
		this.props.changeLocale(locale);
	}

	render() {
		let locales = [];
		for(let locale in localeList){
			if(locales.length > 0){
				locales.push(
						(<span key="sep-{locale}">/</span>)
				);
			}
			let buttonClass = 'language-button';
			if(this.props.locale !== locale){
				buttonClass+= ' active';
			}
			locales.push(
					(<button className={buttonClass} onClick={() => this.changeLocale(locale)} key={locale}>{localeList[locale]}</button>)
			);
		}

		return (
			<div className="language-switcher">
				{locales}
			</div>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);