import React from 'react'
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl'

import {selectBoss} from '../actions/index.js'

const mapStateToProps = (state) => {
	return {
		bossSelected: state.page.bossSelected
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		selectBoss: boss => dispatch(selectBoss(boss)),
	};
};


class Hunt extends React.Component {
	render() {
		switch(this.props.bossSelected){
		case 1:
			return (
				<div className="page hunt spacer-right">
					<FormattedMessage id="button.back">{txt => <div className="back-button" onClick={() => this.props.selectBoss(null)}>{txt}</div>}</FormattedMessage>
					<img className="illustration" alt="boss1 illutration" src="/images/huntactive1.png"/>
					<FormattedMessage id="hunt.boss.name1">{txt => <h2>{txt}</h2>}</FormattedMessage>
					<FormattedMessage id="hunt.boss.description1">{txt => <p>{txt}</p>}</FormattedMessage>
				</div>
			);
		case 2:
			return (
					<div className="page hunt spacer-right">
						<FormattedMessage id="button.back">{txt => <div className="back-button" onClick={() => this.props.selectBoss(null)}>{txt}</div>}</FormattedMessage>
						<img className="illustration" alt="boss2 illutration" src="/images/huntactive2.png"/>
						<FormattedMessage id="hunt.boss.name2">{txt => <h2>{txt}</h2>}</FormattedMessage>
						<FormattedMessage id="hunt.boss.description2">{txt => <p>{txt}</p>}</FormattedMessage>
					</div>
			);
		case 3:
			return (
					<div className="page hunt spacer-right">
						<FormattedMessage id="button.back">{txt => <div className="back-button" onClick={() => this.props.selectBoss(null)}>{txt}</div>}</FormattedMessage>
						<img className="illustration" alt="boss3 illutration" src="/images/huntactive3.png"/>
						<FormattedMessage id="hunt.boss.name3">{txt => <h2>{txt}</h2>}</FormattedMessage>
						<FormattedMessage id="hunt.boss.description3">{txt => <p>{txt}</p>}</FormattedMessage>
					</div>
			);
		default:
			return (
				<div className="page hunt default">
					<FormattedMessage id="hunt.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
					<FormattedMessage id="hunt.body">{txt => <p>{txt}</p>}</FormattedMessage>
					<div className="boss-list">
						<div className="huntboss1" onClick={() => this.props.selectBoss(1)} />
						<div className="huntboss2" onClick={() => this.props.selectBoss(2)} />
						<div className="huntboss3" onClick={() => this.props.selectBoss(3)} />
					</div>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Hunt);