import {CHANGE_PAGE, SELECT_PAGE, OPEN_MENU, CLOSE_MENU, CHANGE_LOCALE, SELECT_BOSS, OPEN_TRAILER, CLOSE_TRAILER} from '../constants/actionType.js' 

export function changePage(pageNumber) {
	return { type: CHANGE_PAGE, page: pageNumber }
};

export function selectPage(pageNumber) {
	return { type: SELECT_PAGE, page: pageNumber }
};

export function openMenu(pageNumber) {
	return { type: OPEN_MENU}
};

export function closeMenu(pageNumber) {
	return { type: CLOSE_MENU}
};

export function changeLocale(newLocale) {
	return { type: CHANGE_LOCALE, locale: newLocale }
};

export function selectBoss(boss) {
	return { type: SELECT_BOSS, boss: boss }
};

export function openTrailer() {
	return { type: OPEN_TRAILER}
};

export function closeTrailer() {
	return { type: CLOSE_TRAILER}
};

