import React from 'react'
import {FormattedMessage} from 'react-intl'

const Gameplay = () => (
	<div className="page gameplay spacer-left">
		<img className="illustration" alt="gameplay illustration" src="/images/gameplay.png" />
		<FormattedMessage id="gameplay.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
		<FormattedMessage id="gameplay.body">{txt => <p>{txt}</p>}</FormattedMessage>
	</div>
)

export default Gameplay