import React from 'react'
import {FormattedMessage} from 'react-intl'

import LanguageSwitcher from './LanguageSwitcher'

const Cover = () => (
		<div className="page cover">
			<video className="page cover video" autoPlay loop muted>
				<source src="/videos/cellyon.mp4" type="video/mp4"/>
			</video>
			<LanguageSwitcher />
			<div className="cover-content">
				<img src="/images/title_white.png" alt="Cellyons' title" className="cellyon-title"/>
				<FormattedMessage id="cover.button">{txt => <a className="cover-button" href="https://store.steampowered.com/app/802120/Cellyon_Boss_Confrontation">{txt}</a>}</FormattedMessage>
			</div>
		</div>
)

export default Cover;