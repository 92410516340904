import React from 'react'
import {FormattedMessage} from 'react-intl'

const JoinUs = () => (
	<div className="page joinus">
		<FormattedMessage id="joinus.title">{txt => <h3>{txt}</h3>}</FormattedMessage>
		<div className="social-media">
			<div>
				<FormattedMessage id="joinus.preorder">{txt => <p>{txt}</p>}</FormattedMessage>
				<a href="https://store.steampowered.com/app/802120/Cellyon_Boss_Confrontation" className="steam">
					<FormattedMessage id="button.preorder">{txt => <p>{txt}</p>}</FormattedMessage>
					<div className="link-image"></div>
				</a>
			</div>
			<div className="separator">
				<div className="line"/>
				<FormattedMessage id="and">{txt => <p>{txt}</p>}</FormattedMessage>
				<div className="line"/>
			</div>
			<div>
				<FormattedMessage id="joinus.discord">{txt => <p>{txt}</p>}</FormattedMessage>
				<a href="https://discord.gg/ErnVfzM" className="discord">
					<FormattedMessage id="button.join">{txt => <p>{txt}</p>}</FormattedMessage>
					<div className="link-image"></div>
				</a>
			</div>
		</div>
		<div className="social-network">
			<FormattedMessage id="joinus.join">{txt => <p>{txt}</p>}</FormattedMessage>
			<FormattedMessage id="link.facebook">{txt => <a href={txt} className="facebook">facebook</a>}</FormattedMessage>
			<FormattedMessage id="link.instagram">{txt => <a href={txt} className="instagram">instagram</a>}</FormattedMessage>
			<FormattedMessage id="link.twitter">{txt => <a href={txt} className="twitter">twitter</a>}</FormattedMessage>
			<FormattedMessage id="link.artstation">{txt => <a href={txt} className="artstation">artstation</a>}</FormattedMessage>
		</div>
		<FormattedMessage id="joinus.footer">{txt => <p className="footer">{txt}</p>}</FormattedMessage>
	</div>
)

export default JoinUs