import React from 'react'
import Slider from 'react-slick';
import {FormattedMessage} from 'react-intl'

class Classes extends React.Component {
	render() {
		let carouselSettings = {
				dots: true,
				dotsClass : 'carousel-dots'
		};
		return (
			<div className="page classes">
				<Slider {...carouselSettings}>
					<div className="carousel-slide">
						<FormattedMessage id="classes.title1">{txt => <h2>{txt}</h2>}</FormattedMessage>
						<div className="illustration-wrapper">
							<img alt="classes illustration" src="/images/classes1.png" />
						</div>
						<FormattedMessage id="classes.body1">{txt => <p>{txt}</p>}</FormattedMessage>
				  	</div>
			  		<div className="carousel-slide">
				  		<FormattedMessage id="classes.title2">{txt => <h2>{txt}</h2>}</FormattedMessage>
				  		<div className="illustration-wrapper">
				  		<img alt="classes illustration" src="/images/classes2.png" />
				  			</div>
				  		<FormattedMessage id="classes.body2">{txt => <p>{txt}</p>}</FormattedMessage>
			  		</div>
			  		<div className="carousel-slide">
				  		<FormattedMessage id="classes.title3">{txt => <h2>{txt}</h2>}</FormattedMessage>
				  		<div className="illustration-wrapper">
				  		<img alt="classes illustration" src="/images/classes3.png" />
				  			</div>
				  		<FormattedMessage id="classes.body3">{txt => <p>{txt}</p>}</FormattedMessage>
			  		</div>
				</Slider>
			</div>
		);
	}
}

export default Classes