import React from 'react'
import Slider from 'react-slick';
import {FormattedMessage} from 'react-intl'

class ComingSoon extends React.Component {
	render() {
		let carouselSettings = {
				dots: true,
				dotsClass : 'carousel-dots'
		};
		return (
			<div className="page comingsoon">
				<FormattedMessage id="comingsoon.title0">{txt => <h2>{txt}</h2>}</FormattedMessage>
				<Slider {...carouselSettings}>
					<div className="carousel-slide">
						<img alt="comingsoon illustration" src="/images/gameplay.png" />
						<div>
							<FormattedMessage id="comingsoon.title1">{txt => <h3>{txt}</h3>}</FormattedMessage>
							<FormattedMessage id="comingsoon.body1">{txt => <p>{txt}</p>}</FormattedMessage>
						</div>
				  	</div>
			  		<div className="carousel-slide">
				  		<img alt="comingsoon illustration" src="/images/comingsoon2.png" />
			  			<div>
					  		<FormattedMessage id="comingsoon.title2">{txt => <h3>{txt}</h3>}</FormattedMessage>
					  		<FormattedMessage id="comingsoon.body2">{txt => <p>{txt}</p>}</FormattedMessage>
				  		</div>
			  		</div>
			  		<div className="carousel-slide">
				  		<img alt="comingsoon illustration" src="/images/comingsoon3.png" />
			  			<div>
					  		<FormattedMessage id="comingsoon.title3">{txt => <h3>{txt}</h3>}</FormattedMessage>
					  		<FormattedMessage id="comingsoon.body3">{txt => <p>{txt}</p>}</FormattedMessage>
				  		</div>
			  		</div>
				</Slider>
			</div>
		);
	}
}

export default ComingSoon