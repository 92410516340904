import React from 'react'
import { connect } from 'react-redux';
import {FormattedMessage} from 'react-intl'

import {openTrailer, closeTrailer} from '../actions/index.js'

const mapStateToProps = (state) => {
	return {
		trailer: state.page.trailer
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		closeTrailer: page => dispatch(closeTrailer()),
		openTrailer: page => dispatch(openTrailer())
	};
};


class About extends React.Component {
	render() {
		if(this.props.trailer){
			return (
				<div className="page about">
					<div className="popup-background">
						<div className="menu-button active" onClick={this.props.closeTrailer}>
							<img src="/images/menubar.png" alt="menubar top" className="top"/>
							<img src="/images/menubar.png" alt="menubar bottom" className="bottom"/>
						</div>
						<div className="popup">
							<div className="video-container">
								<iframe
									title="trailer-video"
									className="embeded-video"
									src="https://www.youtube.com/embed/VKVSD-vl2VA"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen>
								</iframe>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div className="page about spacer-right">
					<img className="illustration" alt="about illustration" src="/images/about.png" />
					<FormattedMessage id="about.title">{txt => <h2>{txt}</h2>}</FormattedMessage>
					<FormattedMessage id="about.body">{txt => <p>{txt}</p>}</FormattedMessage>
					<FormattedMessage id="trailer.button">
						{txt => <button className="play-trailer activator" onClick={this.props.openTrailer}><img src="/images/play.png" alt="play button" />{txt}</button>}
					</FormattedMessage>
				</div>
			);
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
